import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { TypeMetadataFields } from './metadata.model';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  private meta!: TypeMetadataFields;
  private renderer: Renderer2;

  constructor(
    private title: Title,
    private metadata: Meta,
    @Inject(DOCUMENT) private document: Document,
    private rendererFactory: RendererFactory2,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  getFullUrl(): string {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const path = this.router.url;
    const queryParams = this.activatedRoute.snapshot.queryParams;
    const fragment = this.activatedRoute.snapshot.fragment;

    let fullUrl = `${protocol}//${host}${path}`;

    if (Object.keys(queryParams).length > 0) {
      const queryString = new URLSearchParams(queryParams).toString();
      fullUrl += `?${queryString}`;
    }

    if (fragment) {
      fullUrl += `#${fragment}`;
    }

    return fullUrl;
  }

  setMetadata(meta?: TypeMetadataFields): void {
    if (!meta) {
      return;
    }
    this.meta = meta;
    this.setName();
    this.setTitle();
    this.setDescription();
    this.setRobots();
  }

  private setName(): void {
    this.metadata.updateTag({ name: 'name', content: this.meta.name });
  }

  private setTitle(): void {
    this.title.setTitle(this.meta.title);
  }

  private setDescription(): void {
    this.metadata.updateTag({ name: 'description', content: this.meta.description });
  }

  updateCanonicalLink(newCanonicalUrl: string) {
    const canonicalLink = this.document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      console.log('Canonical tag updated', canonicalLink);
      this.renderer.setAttribute(canonicalLink, 'href', newCanonicalUrl);
    }
  }

  private setRobots(): void {
    const follow = this.meta.follow ? 'follow' : 'nofollow';
    const index = this.meta.index ? 'index' : 'noindex';

    this.metadata.updateTag({ name: 'robots', content: `${index}, ${follow}` });
  }
}
